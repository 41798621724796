<template>
    <maintenance-page
        page-title="User"
        page-icon="mdi-account"
        collection-name="user"
        done-redirect-url="/auth/admin/user?isHistory=true"
    />
</template>

<script>
import MaintenancePage from '../../../components/pages/Maintenance';

export default {
    name: 'UserDetail',
    components: { MaintenancePage },
};
//
// async getFormData() {
//     const id = this.$route.params.id;
//     const userResult = await api.get(this.$store, `user/${id}`);
//     console.log('user detail get result',{ userResult });
//     this.formModel = { ...userResult.data };
//
//     const allGroupsResult = await api.get(this.$store, `acpgroup`);
//     console.log('all groups get result',{ allGroupsResult });
//     this.allGroups = allGroupsResult.data;
//
//     const allUsersResult = await api.get(this.$store, `user`);
//     console.log('all users get result',{ allUsersResult });
//     this.allUsers = allUsersResult.data;
//     //console.log('get form data ',this.allUsers[0]);
//     this.allGroups = this.allGroups.map(oneGroup => ({
//         ...oneGroup,
//         isChanged: false,
//         isMember: !!(oneGroup.userLinks && oneGroup.userLinks.find(oneUser => oneUser.id === id)),
//     }));
//     console.log('getForm', this.formModel);
// },
// logForm() {
//     console.log(JSON.stringify(this.formModel, null, 2));
// },
// async saveFormData() {
//     console.log('saveForm', this.formModel);
//
//     const result = await api.post(this.$store, 'user', this.formModel);
//     this.allGroups = await Promise.all(
//         this.allGroups.map(async oneGroup => {
//             const saveGroup = {
//                 userLinks: [],
//                 ...oneGroup,
//                 isChanged: undefined,
//                 isMember: undefined,
//             };
//             if (oneGroup.isChanged) {
//                 if (oneGroup.isMember) {
//                     saveGroup.userLinks.push({
//                         id: this.formModel.id,
//                         text: this.formModel.userCode,
//                     });
//                 } else {
//                     saveGroup.users = saveGroup.userLinks.filter(
//                         oneUser => oneUser.id !== this.formModel.id,
//                     );
//                 }
//                 oneGroup.isChanged = false;
//                 console.log('saveGroup', saveGroup);
//                 await api.post(this.$store, 'acpgroup', saveGroup);
//                 return {
//                     ...saveGroup,
//                     isChanged: false,
//                     isMember: oneGroup.isMember,
//                 };
//             }
//             return oneGroup;
//         }),
//     );
//     this.formModel = result.data;
// },
</script>
